import React, { Component } from "react";
import "./Footer.css";
import { Container } from "reactstrap";

export class Footer extends Component {

    render() {
        return(
            <div className="container foot">
                <span>Version: abp_master_20250103.1</span>
            </div>
        )
    }




}
