import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import { debugLog } from "../utils/Util";

function BAVRadioGroup(props) {
  const [bankRecommendation, setbankRecommendation] = useState(null);
  const [checked, setChecked] = useState(evaluateChecked());
  const [disabled, setDisabled] = useState();
  // Auth Token
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
    };
    const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
    });
  useEffect(() => {
    initToken();
  }, []);
  useEffect(() => {
    if (bankRecommendation){
        if (props.invClientRisk == 1)  { // Risikofähigkeit kann nicht manuell verändert werden, daher immer vorgeschlagenen Wert übernehmen
            var checkedValue = props.responses[bankRecommendation-1].responseId
        } else {
            var checkedValue = evaluateChecked();
        }
        var response = null;
        for (var i = 0; i < props.responses.length; i++){
            if(props.responses[i].responseId == checkedValue){
                response = props.responses[i];
            }
        }
        if (response){
            changeSelection(String(response.responseId), response);
        }
    }
  }, [bankRecommendation]);


  useEffect(() => {
    if (options) {
      const fetchInvestmentRecommendation = async () => {
        try {
            reInitToken
                .then((res) => {
                    fetch(
                        "/profileform/investmentRecommendation/" +
                        props.responses[0].entryId,
                        options
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            setbankRecommendation(data);
                        })
                });
       
         
        } catch (error) {
          console.error(
            "Fehler beim Abrufen der Investment-Empfehlung:",
            error
          );
        }
      };
      const fetchInvestmentStrategy = async () => {
        try {
            reInitToken
                .then((res) => {
                    fetch(
                        "/profileform/investmentStrategy/" + props.responses[0].entryId,
                        options
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            setbankRecommendation(data);
                        })
                });

        } catch (error) {
          console.error("Fehler beim Abrufen der Investment Strategie:", error);
        }
      };
      const fetchClientRiskReadyness = async () => {
        try {
            reInitToken
                .then((res) => {
                    fetch(
                        "/profileform/clientRisk/" + props.responses[0].entryId,
                        options
                    )
                        .then((res) => res.json())
                        .then((data) => {
                            setbankRecommendation(data);
                        })
                });
          
        } catch (error) {
          console.error("Fehler beim Abrufen der Risikofähigkeit", error);
        }
      } 

      if (props.invRec == 1) {
        fetchInvestmentRecommendation();
      }
      if (props.invStrat == 1) {
        fetchInvestmentStrategy();
      }
      if (props.invClientRisk == 1){
        fetchClientRiskReadyness();
      }
    }
  }, [options]);

  function changeSelection(value, response) {
    setDisabled(true);
    if (typeof props.callback == "function") {
      props.callback(props, value, response);
    }
    setChecked(value);

      if (props.invClientRisk || props.readOnly) {
        setDisabled(true);
    } else {
        setDisabled(false);
    }

  }

  function evaluateChecked(){
      for (var i = 0; i < props.responses.length; i++){
          if (props.responses[i].responseId == props.responses[i].value_1){
              return props.responses[i].responseId;
          }
      }
      if (bankRecommendation != null){
          return props.responses[bankRecommendation-1].responseId;
      }
  }
    if (props.readOnly) {
        return (
            <>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="bav-radio-buttons-group-label"
                        defaultValue=""
                        name="bav-radio-buttons-group"
                        className="bavRadioGroup"
                    >
                        {
                            props.responses.map((_response, _index) => (
                                <FormControlLabel
                                    value={_response.responseId}
                                    key={
                                        _response.questionId +
                                        "_" +
                                        _response.responseId +
                                        "_" +
                                        _response.entryDetailId
                                    }
                                    disabled={true}
                                    control={
                                        <Radio
                                            id={"bavRadio" + _response.responseId + "_" + _index}
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#006e58",
                                                },
                                            }}
                                            onChange={(e) => changeSelection(e.target.value, _response)}
                                            checked={_response.responseId == checked}
                                        />
                                    }
                                    label={_response.response}
                                    className={
                                        _index + 1 == bankRecommendation &&
                                            (props.invRec == 1 || props.invStrat == 1 || props.invClientRisk == 1)
                                            ? "invRec"
                                            : ""
                                    }
                                />
                            ))}
                    </RadioGroup>
                </FormControl>
            </>
        );
    } else {
        return (
            <>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="bav-radio-buttons-group-label"
                        defaultValue=""
                        name="bav-radio-buttons-group"
                        className="bavRadioGroup"
                    >
                        {
                            props.responses.map((_response, _index) => (
                                <FormControlLabel
                                    value={_response.responseId}
                                    key={
                                        _response.questionId +
                                        "_" +
                                        _response.responseId +
                                        "_" +
                                        _response.entryDetailId
                                    }
                                    disabled={disabled || props.invClientRisk == 1}
                                    control={
                                        <Radio
                                            id={"bavRadio" + _response.responseId + "_" + _index}
                                            sx={{
                                                "&.Mui-checked": {
                                                    color: "#006e58",
                                                },
                                            }}
                                            onChange={(e) => changeSelection(e.target.value, _response)}
                                            checked={_response.responseId == checked}
                                            disabled={disabled}
                                        />
                                    }
                                    label={_response.response}
                                    className={
                                        _index + 1 == bankRecommendation &&
                                            (props.invRec == 1 || props.invStrat == 1 || props.invClientRisk == 1)
                                            ? "invRec"
                                            : ""
                                    }
                                />
                            ))}
                    </RadioGroup>
                </FormControl>
            </>
        );
    }
}
export default BAVRadioGroup;
