import * as React from "react";
import Button from "@mui/material/Button";
import Box from  "@mui/material/Box";
import { Input } from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  deDE,
  GridToolbar,
} from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import { msalInstance } from "../index";
import Alert from "@mui/material/Alert";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import { useEffect } from "react";
import BAVButton from "./BAVButton";
import BAVMenuButton from "./BAVMenuButton";
import { convertUTCDateToLocalDate } from '../utils/Util.jsx';
import { debugLog } from "../utils/Util";

const queryClient = new QueryClient();

export default function PfSearchDialog({ kunde, callback, sparplan, sparplanCallback,  vorsorgeCallback }) {
  return (
    <QueryClientProvider client={queryClient}>
      <PfSearch kunde={kunde} callback={callback} sparplan={sparplan}/>
    </QueryClientProvider>
  );
}

function PfSearch({ kunde, callback, sparplan}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", text: "" });
  const [validationAlertData, setValidationAlertData] = useState({ severity: "info", text: "" });
  const [newPf, setNewPf] = useState();
  const [pfRoot, setPfRoot] = useState();
  const [openNewPf, setOpenNewPf] = useState(false);
  const [entryType, setEntryType] = useState();
  // Auth Token
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
  };
 
  const account = msalInstance.getActiveAccount();
  useEffect(() => {
    initToken();
  }, []);
  const handleClickOpen = (entryType) => {
     setEntryType(entryType);
     setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRowDoubleClick = () => {
    handleSelect();
  };
  const handleSelect = () => {
    if (selection.length > 0) {
      var row = allPfs.find((item) => item.id == selection);
      handlePostPortfolio(row);
    } else {
      setAlertData({
        severity: "info",
        text: "Bitte wählen Sie ein Portofolio aus",
      });
      setShowAlert(true);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setShowValidationAlert(false);
  };

  const handleNeu = () => {
      //setNewPf(kunde.editKdNr+"-");
      setPfRoot(kunde.editKdNr + "-");
      setOpenNewPf(true);
  }
  const handleCloseNewPf = () => {
      handleCloseAlert();
      setOpenNewPf(false);
  }
  
  
  const handleCreateNeu = () => {
      // hier erst noch Format prüfen
      var valid = newPf ? newPf.match(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$/) !== null : false;
      var validNumber = true;
      if (entryType == 2) {
          validNumber = newPf ? newPf.replaceAll(".", "").replaceAll("-", "").match(/^[0-9]{0,}(3[0-9]|7[0-9])$/) !== null : false;
      }
      if (entryType == 3) {
          validNumber = newPf ? newPf.replaceAll(".", "").replaceAll("-", "").match(/^[0-9]{0,}(3[0-9])$/) !== null : false;
      }
      // Vorsorge nur 10 erlaubt
      if (entryType == 4) {
          validNumber = newPf ? newPf.replaceAll(".", "").replaceAll("-", "").match(/^[0-9]{0,}(1[0])$/) !== null : false;
      }
      if (valid && validNumber) {
          const data = {
              kdlnr: kunde.kdLnr,
              ktgnr: newPf?newPf.replaceAll(".","").replaceAll("-",""):0,
              kdlnrberat:0,
              editktgnr: newPf,
              kdbezberater: 'Berater',
              rubrik:'Portfolio'

          }
          handlePostPortfolio(data);
      } else {
          if (!valid) {
              setValidationAlertData({
                  severity: "error",
                  text: <div>Bitte geben Sie die Portfolionummer <br />  im korrekten Format ein [XX.XXX.XXX-XX].</div>,
              });
          }
          if (!validNumber) {
              setValidationAlertData({
                  severity: "error",
                  text: entryType == 2 ? < div > Bitte verwenden Sie nur Portfolionummern im gültigen Bereich(30-39 oder 70-79)</div > : entryType == 3 ? < div > Bitte verwenden Sie nur Portfolionummern im gültigen Bereich(30-39)</div > : < div > Für Vorsorgeportfolios ist nur der Bereich 10 erlaubt</div >,
              });
          }
      setShowValidationAlert(true);
      }
  }
  const handleChangeNewPf = ev => {
      setNewPf(pfRoot + ev.target.value);
  }



    const postPortfolio = async (portfolioData) => {
        const response = await fetch("/portfolios", {
            method: "POST",
            headers: options.headers,
            body: JSON.stringify(portfolioData),
        });
        if (!response.ok) {
            var msg = 'Portfolio konnte nicht erstellt werden.';
            if (response.status == 409) {
                msg = 'Portfolionummer bereits vorhanden.'
            }
            throw new Error(msg);
        }

    };
  //const postMutation = useMutation(postPortfolio);
  const postMutation = useMutation(postPortfolio);
  const handlePostPortfolio = async (data) => {
    const newPortfolio = {
      customerId: kunde.customerId,
      kdLnr: data.kdlnr,
      ktGnr: data.ktgnr,
      kdLnrBerat: data.kdlnrberat,
      editktgnr: data.editktgnr,
      kdBeratBez: data.kdbezberater,
      rubrik: data.rubrik,
      createDate: convertUTCDateToLocalDate(new Date()),
      createdBy: account.name,
      changeDate: convertUTCDateToLocalDate(new Date()),
      changedBy: account.name,
    };
    try {
      const postedPortfolio = await postMutation.mutateAsync(newPortfolio);
       
      if (postedPortfolio) {
        var portfolioId = postedPortfolio.portfolioId;
      } else {
          const pf = await fetch(
              "/portfolios/byKtgnr?ktgnr=" +
              newPortfolio.ktGnr +
              "&customerId=" +
              newPortfolio.customerId,
              options
          ).then((res) => res.json());
          var portfolioId = pf.portfolioId;
      }
    } catch (error) {
        if (openNewPf) {
            setValidationAlertData({
                severity: "error",
                text: <div>{error.message}</div>,
            });
            setShowValidationAlert(true);
        } else {
            setAlertData({
                severity: "error",
                text: <div>{error.message}</div>
            });
            setShowAlert(true);
        }
        return;
    }
    setOpen(false);
    callback(portfolioId, entryType);
  };
  const columns: GridColDef[] = [
    { field: "editktgnr", headerName: "Portfolio", width: 150 },
    { field: "rubrik", headerName: "Bezeichnung", width: 200 },
    { field: "kdbezberater", headerName: "Berater", width: 200 },
  ];
    const fetchPortfolioData = async () => {
        setShowAlert(false);
        setLoading(true);
        const res = await fetch("/pfsearch?kdlnr=" + kunde.kdLnr, options);
        setLoading(false);
        return res.json();
    };
  const { data: allPfs, refetch } = useQuery("PfSearch", fetchPortfolioData, {
    enabled: !!options,
  });

  if(!allPfs){
      
      return 'Loading';
  } 
 
  return (
    <>
    {sparplan==true &&
            <BAVMenuButton
            opener={handleClickOpen}
         >
         Neu
         </BAVMenuButton>
    }
    {!sparplan &&
    <BAVButton
        className="m-3"
        variant="contained"
        style={{
          maxWidth: "120px",
          maxHeight: "25px",
          minWidth: "30px",
          minHeight: "25px",
        }}
        onClick={function(){handleClickOpen(2)}}
      >
        Neu
      </BAVButton>
      }
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={false}
          maxWidth={"md"}
        >
          <DialogTitle>Portfolioauswahl</DialogTitle>
          <DialogContent style={{ height: "300px", minWidth: "500px" }}>
              <Box sx={{ width: '100%', height: '70%'}}>  
                  <DataGrid
                              disableColumnMenu={true}
                              loading={loading}
                              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                              onRowDoubleClick={handleRowDoubleClick}
                              columns={columns}
                              rows={allPfs}
                              rowHeight={40}
                              density={"compact"}
                              autoPageSize={false}
                              hideFooterPagination={true}
                              hideFooter={true}
                              onRowSelectionModelChange={(newSelection) => {
                                  setSelection(newSelection);
                              }}
                    />
                    <BAVButton variant="outlined" size="small" onClick={handleNeu} sx={{mt: 2}}>
                      + Neues Portfolio
                    </BAVButton>
                    <Dialog
                        open={openNewPf}
                        fullWidth={false}
                        maxWidth={"md"}>
                     <DialogContent>
                        <Box>
                         <span>Portfolio-Nummer </span>
                                      <br />
                                      <span style={{
                                          paddingBottom: 6, fontFamily: 'Roboto, Helvetica, Arial, sans- serif',
                                          fontWeight: 400,
                                          fontSize: '1rem',
                                      lineHeight: '1.4375em',
                                      letterSpacing: '0.00938em',
                                          borderBottom: '1px solid rgba(0, 0, 0, 0.42)' }}>{pfRoot}</span><Input onChange={handleChangeNewPf} inputProps={{ maxLength: 2 }} sx={{width:40} } />
                        </Box>
                         <DialogActions>
                             <BAVButton variant="contained" size="small" onClick={handleCloseNewPf} >
                             Abbrechen
                            </BAVButton> 
                            <BAVButton variant="contained" size="small" onClick={handleCreateNeu} >
                             Übernehmen
                            </BAVButton>
                        </DialogActions>
                           {showValidationAlert && (
                              <Alert severity={validationAlertData.severity} onClose={handleCloseAlert}>
                                {validationAlertData.text}
                               </Alert>
                            )}
                    </DialogContent>
                    </Dialog>

              </Box>
          </DialogContent>
          {showAlert && (
            <Alert severity={alertData.severity} onClose={handleCloseAlert}>
              {alertData.text}
            </Alert>
          )}
          <DialogActions>
            <BAVButton variant="contained" onClick={handleClose}>
              Abbrechen
            </BAVButton>
            <BAVButton variant="contained" onClick={handleSelect}>
              Auswählen
            </BAVButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
