import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconButton, DialogContent, Dialog, DialogTitle, DialogActions, Paper, Container } from "@mui/material";
import { useState, useEffect } from "react";
import {
    QueryClient,
    QueryClientProvider,
    useQuery
} from "react-query";

import {
    DataGrid,
    GridColDef,
    GridValueGetterParams,
    deDE,
    GridToolbar,
} from "@mui/x-data-grid";
import HistoryIcon from '@mui/icons-material/History';
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import "./BAVHistoryPopup.css";
import { debugLog } from "../utils/Util";
import BAVButton from "./BAVButton";

const queryClient = new QueryClient();
export default function BAVHistoryPopup({ entryId }) {
    return (
        <QueryClientProvider client={queryClient}>
            <BAVHistoryPopupDlg entryId={entryId} />
        </QueryClientProvider>
    );
}
function BAVHistoryPopupDlg({ entryId }) {
    const [open, setOpen] = React.useState(false);
    // Auth Token
    const [options, setOptions] = useState();
    const [selection, setSelection] = useState();
    const [history, setHistory] = useState([]);
    const [entryHistory, setEntryHistory] = useState([]);
    const [showAdditionsHeaders, setShowAdditionsHeaders] = useState(false);
    const [token, setToken] = useState();
    const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
    };
    const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
    });
    useEffect(() => {
        initToken();
    }, []);

    useEffect(() => {
        if (open) {
            let requestOptionsEntry = {
                method: "GET",
                headers: options.headers
            };
            fetch(`/history/entryHistory/${entryId}`, requestOptionsEntry)
                .then(function (result) {
                    console.log(result);
                    return result.json();
                })
                .then(function (data) {

                    setEntryHistory(data);
                });
        }
    }, [open]);

    const { data: questions, status } = useQuery(
        ["questions", entryId],
        () => {
            return fetch(`/history?entryId=${entryId}`, options).then((res) =>
                res.json()
            );
        },
        { enabled: !!options && !!open }
    );
    useEffect(() => {
        if (selection) {
            let requestOptions = {
                method: "GET",
                headers: options.headers
            };
            reInitToken
                .then((res) => {
                    fetch(`/history/entryHistory/${entryId}/${selection}`, requestOptions)
                        .then(function (result) {
                            return result.json();
                        })
                        .then(function (data) {
                            if (data.length > 0) {
                                setShowAdditionsHeaders(data[0].responseType == 2);
                            } else {
                                setShowAdditionsHeaders(false);
                            }
                            setHistory(data);
                        })
                       

                });

        }
    }, [selection]);


    const handleClose = () => {
        setOpen(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleMainRowClick = (e) => {
        setSelection(e.row.questionId);
    }
    const columnsQuestion: GridColDef[] = [

        {
            field: "question",
            headerName: "Frage",
            headerClassName: "BAVColumnHeader",
            flex: 1
        },
    ]
    const columnsHistory: GridColDef[] = [
        {
            field: "periodStart",
            headerName: "Gültig von",
            headerClassName: "BAVColumnHeader",
            width: 150,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false
        },
        {
            field: "periodEnd",
            headerName: "Gültig Bis",
            headerClassName: "BAVColumnHeader",
            width: 150,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false
        },
        {
            field: "response",
            headerName: "Antwort",
            headerClassName: "BAVColumnHeader",
            flex: 2,
            sortable: false
        },
        {
            field: "kenntnisse",
            headerName: showAdditionsHeaders ? "Kenntnisse" : "",
            headerClassName: "BAVColumnHeader",
            flex: 1,
            sortable: false
        },
        {
            field: "erfahrung",
            headerName: showAdditionsHeaders ? "Erfahrung" : "",
            headerClassName: "BAVColumnHeader",
            flex: 1,
            sortable: false
        },
        {
            field: "aufklaerung",
            headerName: showAdditionsHeaders ? "Aufklärung" : "",
            headerClassName: "BAVColumnHeader",
            flex: 1,
            sortable: false
        },
        {
            field: "changedBy",
            headerName: "Geändert von",
            headerClassName: "BAVColumnHeader",
            flex: 1,
            sortable: false
        }
    ]

    const columnsEntryHistory: GridColDef[] = [
        {
            field: "periodStart",
            headerName: "Periode von",
            headerClassName: "BAVColumnHeader",
            width: 135,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false
        },
        {
            field: "periodEnd",
            headerName: "Periode Bis",
            headerClassName: "BAVColumnHeader",
            width: 135,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false
        },
        {
            field: "active",
            headerName: "Aktiv",
            headerClassName: "BAVColumnHeader",
            width: 100,
            sortable: false,
            valueGetter: ({ value }) => 
                value &&
            value==1?'Ja':'Nein'
        },
        {
            field: "createDate",
            headerName: "Erstellt",
            headerClassName: "BAVColumnHeader",
            width: 135,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false

        },
        {
            field: "createdBy",
            headerName: "Erstellt von",
            headerClassName: "BAVColumnHeader",
            width: 130,
            sortable: false
        },
        {
            field: "changeDate",
            headerName: "Geändert",
            headerClassName: "BAVColumnHeader",
            width: 135,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false

        },
        {
            field: "changedBy",
            headerName: "Geändert von",
            headerClassName: "BAVColumnHeader",
            width: 135,
            sortable: false
        },
        {
            field: "validFrom",
            headerName: "Gültig von",
            headerClassName: "BAVColumnHeader",
            width: 135,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false

        },
        {
            field: "validTo",
            headerName: "Gültig bis",
            headerClassName: "BAVColumnHeader",
            width: 135,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric"
                }),
            sortable: false

        }   ];
    if (status == 'loading' ) {
        return;
    }
    return (
        <>
            <IconButton color="primary" sx={{
                '&:hover': {
                    backgroundColor: '#f2b7026e',
                    cursor: 'default'
                }
            }} onClick={handleClickOpen}>
                <HistoryIcon />
            </IconButton>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
            >
                <DialogTitle>History</DialogTitle>
                <DialogContent>
                    <Paper>
                        <Container className="border bg-light p-1">
                            <h6>Profil-History</h6>
                            <div style={{ height: 200, width: '100%' }}>
                                <DataGrid
                                    className="BAVEntryTable"
                                    getRowId={(row) => row.questionId}
                                    disableColumnMenu={true}
                                    loading={status == 'loading'}
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                    rows={questions}
                                    columns={columnsQuestion}
                                    disableColumnFilter={true}
                                    density={'compact'}
                                    rowHeight={28}
                                    columnHeaderHeight={30} // for columnHeader height
                                    hideFooterPagination={true}
                                    hideFooter={true}
                                    onRowClick={handleMainRowClick}
                                />
                            </div>
                            <div style={{ height: 150, width: '100%' }}>
                                <DataGrid
                                    className="BAVEntryTable"
                                    getRowId={(row) => "" + row.periodStart + "-" + row.questionId + "-" + Math.random()}
                                    disableColumnMenu={true}
                                    loading={status == 'loading'}
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                    rows={history}
                                    columns={columnsHistory}
                                    disableColumnFilter={true}
                                    density={'compact'}
                                    rowHeight={28}
                                    columnHeaderHeight={30} // for columnHeader height
                                    hideFooterPagination={true}
                                    hideFooter={true}
                                    //sx={{marginTop:'10px'}}
                                    getRowClassName={(params) => `history-${params.row.periodEnd > '2999-01-01' ? 'active' : ''}`}
                                />
                            </div>
                            <h6>Aktivierung-History</h6>
                            <div style={{ height: 200, width: '100%' }}>
                                <DataGrid
                                    className="BAVEntryTable"
                                    getRowId={(row) => "" + row.periodStart + "-" + row.questionId + "-" + Math.random()}
                                    disableColumnMenu={true}
                                    loading={status == 'loading'}
                                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                                    rows={entryHistory}
                                    columns={columnsEntryHistory}
                                    disableColumnFilter={true}
                                    density={'compact'}
                                    rowHeight={28}
                                    columnHeaderHeight={30} // for columnHeader height
                                    hideFooterPagination={true}
                                    hideFooter={true}
                                    //sx={{marginTop:'10px'}}
                                    getRowClassName={(params) => `history-${params.row.periodEnd > '2999-01-01' ? 'active' : ''}`}
                                />
                            </div>
                        </Container>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <BAVButton
                        onClick={handleClose}
                        className="m-3"
                        variant="contained">Schliessen</BAVButton>
                </DialogActions>
            </Dialog>
        </>
    )

}

