import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { debugLog } from "../utils/Util";
import { Alert } from '@mui/material';
import Collapse from '@mui/material/Collapse';

function BAVRadioGroupESG(props) {
    const [disabled, setDisabled] = useState();
    const [notAllowed, setNotAllowed] = useState("0");
  function changeText(e) {
      var regExp = /^[A-Za-z0-9_. \/\-():\r\näüöÄÖÜ",&'%*+=?!]+$/g;
      var valid = regExp.test(e.target.value) || e.target.value=="";
      if (!valid) {
          setNotAllowed(e.target.value.substring(e.target.value.length - 1));
          return;
      } else {
          setNotAllowed("0");
      }
      setTextValue(e.target.value);
  }

    function changeSelection(e, response) {
      debugLog('blur');
      setDisabled(true);
    if (typeof props.callback == "function") {
      props.callback(props, e.target.value, response, e.target);
    }
    setDisabled(false);
  }

  const [textValue, setTextValue] = useState(
    props.responses[2] ? props.responses[2].value_1 ? props.responses[2].value_1 : "" : ""
  );
  function renderRadio() {
    const objs: JSX.Element[] = [];
    if (props.responses.length > 0) {
      for (let i = 0; i < props.responses.length; i++) {
        if (i < 2) {
          objs.push(
            <FormControlLabel
              value={props.responses[i].responseId}
              key={
                props.responses[i].questionId +
                "_" +
                props.responses[i].responseId +
                "_" +
                props.responses[i].entryDetailId
              }
              control={
                <Radio
                  id={"bavRadioCond" + props.responses[i].responseId + "_" + i}
                  sx={{
                    "&.Mui-checked": {
                      color: "#006e58",
                    },
                  }}
                  onChange={(e) => changeSelection(e, props.responses[i])}
                  checked={
                    props.responses[i].responseId == props.responses[i].value_1
                  }
                      disabled={disabled || props.readOnly}
                />
              }
              label={props.responses[i].response}
            />
          );
        }
      }
    }
    return objs;
  }

  function renderTextarea() {
    if (props.responses.length > 0) {
      for (let i = 0; i < props.responses.length; i++) {
        if (i == 2) {
          return (
            <>
              <Typography variant="body1" ml={1} mt={1}>
                {props.responses[i].response}
              </Typography>
              <Typography variant="body1" ml={1} mt={1}>
                <textarea
                          rows={4}
                          cols={60}
                          style={{ marginTop: "10px", marginLeft: "12px" }}
                          //key={props.responses[i].questionId+props.responses[i].responseId+'txt'}
                          value={textValue}
                          onBlur={(e) => changeSelection(e, props.responses[i])}
                          onChange={(e) => changeText(e)}
                          disabled={props.readOnly}
                />
              </Typography>
            </>
          );
        }
      }
    }
  }

  return (
    <>
          <FormControl>
              <RadioGroup
                  aria-labelledby="bav-radio-buttons-groupCond-label"
                  defaultValue=""
                  name="bav-radio-buttons-groupCond"
                  className="bavRadioGroupCond"
                  style={{ paddingTop: "10px", paddingLeft: "12px" }}
                  disabled={props.readOnly}
        >
          {renderRadio()}
        </RadioGroup>
          </FormControl>
          {renderTextarea()}
          <Collapse in={notAllowed != "0"}>
              <Alert variant="filled" severity="error">Das Zeichen {notAllowed} ist nicht erlaubt</Alert>
          </Collapse>
      </>
  );
}
export default BAVRadioGroupESG;
