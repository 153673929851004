import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { debugLog } from "../utils/Util";
import {
  DataGrid,
  GridColDef,
  deDE,
} from "@mui/x-data-grid";
import "./BAVEntryTable.css";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import { useEffect } from "react";
import BAVButton from "./BAVButton";
import { Container, Row, Col } from "reactstrap";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
} from "react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { msalInstance } from "../index";
import {convertUTCDateToLocalDate} from '../utils/Util.jsx';
const queryClient = new QueryClient();


export default function CustomerSearchDialog() {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomerSearch />
    </QueryClientProvider>
  );
}

function CustomerSearch() {
  var selectedRowIndex = -1;
  const [open, setOpen] = useState(false);
  const [customerdata, setCustomerdata] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("");
  const navigate = useNavigate();
  const account = msalInstance.getActiveAccount();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selection, setSelection] = useState({});
  const [alertData, setAlertData] = useState({ severity: "info", text: "" });
  const columns: GridColDef[] = [
    {
      field: "kdnr",
      headerName: "Kunden-Nr",
      headerClassName: "BAVColumnHeader",
      width: 120,
    },
    {
      field: "editkdnr",
      headerName: "Edit. Kd-Nr",
      headerClassName: "BAVColumnHeader",
      width: 120,
    },
    {
      field: "kdbez",
      headerName: "Kunde",
      headerClassName: "BAVColumnHeader",
      width: 220,
    },
    {
      field: "gebdat",
      headerName: "Geburtsdatum",
      headerClassName: "BAVColumnHeader",
      width: 135,
      valueGetter: ({ value }) =>
        value &&
        new Date(value).toLocaleString("de-CH", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
    },
    {
      field: "ort",
      headerName: "Ort",
      headerClassName: "BAVColumnHeader",
      width: 120,
    },
  ];
  // Auth Token
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
  };
    const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
    });
  useEffect(() => {
    initToken();
  }, []);

  const searchCustomers = useMutation(
    (searchCriteria) => {
      setShowAlert(false);
      setLoading(true);
      return reInitToken
        .then((res) => {
            return fetch(
                "clientsearch?kdnr=" + searchCriteria + "&type=or",
                options
            ).then((res) => res.json());
        });
    
    },
    {
      onSuccess: (data) => {
        setCustomerdata(data);
        setLoading(false);
      },
    }
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCustomerdata([]);
    setOpen(false);
    setShowAlert(false);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  function selectCallback(success, msg) {
    if (!success) {
      setAlertData({ severity: "error", text: msg });
      setShowAlert(true);
    } else {
      setAlertData({});
      setShowAlert(false);
      setOpen(false);
    }
  }
  const handleSelect = () => {
    // Check if Client selected
    if (selection.length > 0) {
      var kd = customerdata.find((item) => item.kdnr == selection);
      handlePostCustomer(kd, selectCallback);
    } else {
      setAlertData({
        severity: "info",
        text: "Bitte wählen Sie einen Kunden aus",
      });
      setShowAlert(true);
    }
  };

  function handleRowDoubleClick(e) {
    handleSelect();
  }

  function getDateValue(value) {
    var dt = new Date(value);
    dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
    return dt.toLocaleDateString("de", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit",
    });
  }

 
  const postCustomer = async (customerData) => {
    // Neuer Kunde anlegen
      return reInitToken
          .then((res) => {
             return fetch("/customers", {
                  method: "POST",
                  headers: options.headers,
                  body: JSON.stringify(customerData),
              })
                  .then((response) => {
                      if (!response.ok) {
                          if (response.status === 409) {
                              throw new Error("Der Kunde ist bereits vorhanden.");
                          } else {
                              debugLog(response);
                              throw new Error("Error posting data.");
                          }
                      }
                      const responseData = response.json();
                      return responseData; // Return the customer record
                  });
          });
     
  };

  const postPortfolio = async (portfolioData) => {
      return reInitToken
          .then((res) => {
              return fetch("/portfolios", {
                  method: "POST",
                  headers: options.headers,
                  body: JSON.stringify(portfolioData),
              })
                  .then((response) => {
                      if (!response.ok) {
                          throw new Error("Porfolio konnte nicht angelegt werden.");
                      }
                  })
              
          });
    
  };

  const postMutation = useMutation(postCustomer);
  const postMutationPf = useMutation(postPortfolio);
  const handlePostCustomer = async (customerData, callback) => {
    const newCustomer = {
      kdLnr: customerData.kdlnr,
      kdNr: customerData.kdnr,
      //surname: customerData.vorname,
      //name: customerData.name,
      //city: customerData.ort,
      //editKdNr: customerData.editkdnr,
      //birthdate: customerData.gebdat,
      //createDate: convertUTCDateToLocalDate(new Date()),
      createdBy: account.name,
      //changeDate: convertUTCDateToLocalDate(new Date()),
      changedBy: account.name,
      active: 1
    };

    const newPortfolio = {
      kdLnr: customerData.kdlnr,
      ktGnr: customerData.ktgnr,
      kdLnrBerat: customerData.kdlnrberat,
      editktgnr: customerData.editktgnr,
      kdBeratBez: customerData.kdbezberater,
      rubrik: customerData.rubrik,
      //createDate: convertUTCDateToLocalDate(new Date()),
      createdBy: account.name,
      //changeDate: convertUTCDateToLocalDate(new Date()),
      changedBy: account.name,
    };

    try {
      const postedCustomer = await postMutation.mutateAsync(newCustomer);
      newPortfolio.customerId = postedCustomer.customerId;
      // Handle success
      debugLog("Data posted successfully");
      const postedPortfolio = await postMutationPf.mutateAsync(newPortfolio);
      navigate("/customerDetail", {
        state: { kunde: postedCustomer, reload: 1 },
      });
      callback(true, "");
      // Perform other actions if needed
    } catch (error) {
      // Handle error
      console.error("Error:", error.message);
      callback(false, error.message);
    }
  };
  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
 
  return (
    <>
      <BAVButton variant="outlined" onClick={handleClickOpen}>
        Neuer Kunde
      </BAVButton>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={false}
          maxWidth={"md"}
        >
          <DialogTitle>Kundensuche</DialogTitle>
          <DialogContent style={{ height: "300px", minWidth: "500px" }}>
            <Container>
              <Row>
                <Col>
                  <TextField
                    id="kdnr"
                    variant="standard"
                    className="p-2"
                    onChange={(e) => setSearchCriteria(e.target.value)}
                    value={searchCriteria}
                  ></TextField>
                  <BAVButton
                    variant="contained"
                    className="ps-3"
                    onClick={() => searchCustomers.mutate(searchCriteria)}
                  >
                    Suche
                  </BAVButton>
                </Col>
              </Row>
            </Container>
            <Container style={{ maxHeight: "80%", overflow: "auto" }}>
              <Container fluid className="pt-2">
                <DataGrid
                  className="BAVEntryTable"
                  getRowId={(row) => row.kdnr}
                  disableColumnMenu={true}
                  loading={loading}
                  localeText={
                    deDE.components.MuiDataGrid.defaultProps.localeText
                  }
                  onRowDoubleClick={handleRowDoubleClick}
                  columns={columns}
                  rows={customerdata}
                  rowHeight={40}
                  density={"compact"}
                  //autoPageSize={true}
                  hideFooterPagination={true}
                  hideFooter={true}
                  onRowSelectionModelChange={(newSelection) => {
                    setSelection(newSelection);
                  }}
                />
              </Container>
            </Container>
          </DialogContent>
          {showAlert && (
            <Alert severity={alertData.severity} onClose={handleCloseAlert}>
              {alertData.text}
            </Alert>
          )}
          <DialogActions>
            <BAVButton variant="contained" onClick={handleClose}>
              Abbrechen
            </BAVButton>
            <BAVButton variant="contained" onClick={handleSelect}>
              Auswählen
            </BAVButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
