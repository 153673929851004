import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BAVEntryTable from "./BAVEntryTable";
import { Container, Row, Col } from "reactstrap";
import { Paper, Box, CircularProgress } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import "./CustomerDetail.css";
import PfSearchDialog from "./PfSearchDialog";
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
    useMutation,
} from "react-query";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import { msalInstance } from "../index";
import BAVButton from "./BAVButton";
import { convertUTCDateToLocalDate } from '../utils/Util.jsx';
import { debugLog } from "../utils/Util";
import ProfileReconfirmationDialog from "./ProfileReconfirmationDialog";

const queryClient = new QueryClient();

export default function CustomerDetail() {
    return (
        <QueryClientProvider client={queryClient}>
            <CreateCustomerDetail />
        </QueryClientProvider>
    );
}

function CreateCustomerDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    var kd = location.state.kunde;
    var doReload = location.state.reload ? location.state.reload : 0;
    // Auth Token
    const [options, setOptions] = useState();
    const [adminRights, setAdminRights] = useState(false);
    const [token, setToken] = useState();
    const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
    };
    const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
    });
    const evaluateAdminRights = async () => {
        await reInitToken
        const res = await fetch('/user/hasAdminRights', options);
        const data = await res.json();
        setAdminRights(data);
    }



    useEffect(() => {
        initToken();
    }, []);

    useEffect(() => {
        if (options) {
            evaluateAdminRights();
        }
    }, [options]);

    const { data: entries, status } = useQuery(
        ["entries", kd.customerId],
        () => {
            return reInitToken
                .then((res) => {
                    return fetch(`/entries/${kd.customerId}`, options).then((res) =>
                        res.json()
                    );
                });

        },
        { enabled: !!options }
    );

    const account = msalInstance.getActiveAccount();

    let kdEntries = [];
    let activeKdEntries = [];
    let pfEntries = [];
    let activePfEntries = [];
    let spEntries = [];
    let activeSpEntries = [];

    let activeEntriesCnt = 0;


    if (entries && entries.length > 0) {
        kdEntries = entries.filter((a) => a.entryType == 1);
        pfEntries = entries.filter((a) => a.entryType == 2);
        spEntries = entries.filter((a) => a.entryType == 3 || a.entryType == 4);
        activeKdEntries = kdEntries.filter((a) => a.active == 1);
        activePfEntries = pfEntries.filter((a) => a.active == 1);
        activeSpEntries = spEntries.filter((a) => a.active == 1);
        activeEntriesCnt = (entries.filter((a) => a.active == 1)).length;
    }


    function handleNewClientProfile(e) {
        var newEntry = {
            customerId: location.state.kunde.customerId,
            entryType: 1,
            validFrom: convertUTCDateToLocalDate(new Date()),
            validTo: new Date("2099-12-31"),
            active: 0,
        };
        createNewEntry(newEntry);
    }

    function handleNewPfProfile(portfolioId, entryType) {
        var newEntry = {
            customerId: location.state.kunde.customerId,
            portfolioId: portfolioId,
            entryType: entryType,
            validFrom: convertUTCDateToLocalDate(new Date()),
            validTo: new Date("2099-12-31"),
            active: 0,
        };
        createNewEntry(newEntry);
    }
    function handleNewSpProfile(portfolioId, entryType) {
        var newEntry = {
            customerId: location.state.kunde.customerId,
            portfolioId: portfolioId,
            entryType: entryType,
            validFrom: convertUTCDateToLocalDate(new Date()),
            validTo: new Date("2099-12-31"),
            active: 0,
        };
        createNewEntry(newEntry);
    }

    function createNewEntry(postData) {
        let requestOptions = {
            method: "POST",
            headers: options.headers,
            body: JSON.stringify(postData),
        };
        reInitToken
            .then((res) => {
                fetch("/entries", requestOptions)
                    .then(function (response) {
                        if (!response.ok) {
                            throw Error(response.statusText)
                        }
                        return response;
                    })
                    .then((response) => response.json())
                    .then(function (response) {
                        kd = response;
                        kd.editKdNr = location.state.kunde.editKdNr;
                        kd.kdnr = location.state.kunde.kdnr;
                        kd.kdLnr = location.state.kunde.kdLnr;
                        kd.surname = location.state.kunde.surname;
                        kd.name = location.state.kunde.name;
                        kd.city = location.state.kunde.city;
                        kd.kundenHauptTypCode = location.state.kunde.kundenHauptTypCode;
                        navigate("/customerProfile", { state: { kunde: kd } });
                    })
                    .catch((error) => {
                        // Handle the error
                        console.log(error)
                    });
            });

    }

    function goBack() {
        navigate("/mainPage", { state: { reload: doReload } });
    }





    if (status !== "success") {
        return (
            <Box sx={{ display: "flex" }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Container className="mainContainer">
                <Paper>
                    <div className="pageContainer">
                        <div>
                            <BAVButton
                                onClick={goBack}
                                className="m-3"
                                variant="contained"
                                style={{
                                    maxWidth: "120px",
                                    maxHeight: "25px",
                                    minWidth: "30px",
                                    minHeight: "25px",
                                }}
                            >
                                Zurück
                            </BAVButton>
                        </div>
                        <Container className="border bg-light p-3" fluid>
                            <Row>
                                <Col>
                                    <b>Kunde</b>
                                </Col>
                            </Row>
                            <Row>
                                {kd.kundenHauptTypCode == 2 ?
                                    <Col className="pt-2">
                                        {kd.kdnr} {kd.surname} und {kd.name}, {kd.city}
                                    </Col> :
                                    <Col className="pt-2">
                                        {kd.kdnr} {kd.surname} {kd.name}, {kd.city}
                                    </Col>

                                }
                                {process.env.REACT_APP_RECONFIRMBUTTON == 'true' ?
                                    <Col className="colConfirmProfile">
                                        <ProfileReconfirmationDialog activeKdEntries={activeKdEntries} activePfEntries={activePfEntries} activeSpEntries={activeSpEntries} />
                                    </Col> : <Col />
                                }
                            </Row>
                        </Container>
                        <Container className="border bg-light p-3" fluid>
                            <Row>
                                <Col>
                                    <b>Beratungsanalyse zum Kunden (Kundenebene)</b>
                                </Col>
                                <Col className="text-end">
                                    <BAVButton
                                        onClick={handleNewClientProfile}
                                        className="m-3"
                                        variant="contained"
                                        disabled={kdEntries.length > 0}
                                        style={{
                                            maxWidth: "120px",
                                            maxHeight: "25px",
                                            minWidth: "30px",
                                            minHeight: "25px",
                                        }}
                                    >
                                        Neu
                                    </BAVButton>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BAVEntryTable
                                        data={kdEntries}
                                        loading={status == "loading"}
                                        showPf={false}
                                        hasAdmin={adminRights}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="border bg-light p-3" fluid>
                            <Row>
                                <Col>
                                    <b>Beratungsanalyse zum Portfolio (Portfolioebene)</b>
                                </Col>
                                <Col className="text-end">
                                    <PfSearchDialog kunde={kd} callback={handleNewPfProfile} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BAVEntryTable
                                        data={pfEntries}
                                        loading={status == "loading"}
                                        showPf={true}
                                        hasAdmin={adminRights}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="border bg-light p-3" fluid>
                            <Row>
                                <Col>
                                    <b>Beratungsanalyse Sparplan / Vorsorge</b>
                                </Col>
                                <Col className="text-end">

                                    <PfSearchDialog kunde={kd} callback={handleNewPfProfile} sparplan={true} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BAVEntryTable
                                        data={spEntries}
                                        loading={status == "loading"}
                                        showPf={true}
                                        hasAdmin={adminRights}
                                        showSpType={true}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Paper>
            </Container>



        </>
    );
}
