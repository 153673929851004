import * as React from "react";
import { Button, Checkbox, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState, useEffect } from "react";
import FactCheckIcon from '@mui/icons-material/FactCheck';

function BAVButtonColumn(props) {
    const { handleAuswertung, handleEdit, setEntryActive, active, handleDelete, hasAdmin, handleShow, profileActive } =
        props;
    const [disabled, setDisabled] = useState(active == 1 ? true : false);
    const [_active, setActive] = useState(active == 1 ? true : false);
    const [showDlgChanged, setShowDlgChanged] = useState(false);
    const [changeConfirmed, setChangeConfirmed] = useState(false);

    function handleActive(e) {
        if (profileActive && !_active && !changeConfirmed) {
            setShowDlgChanged(true);
            return;
        }
          setDisabled((oldvalue) => !oldvalue);
          var newval = !_active;
          setActive(newval);
          setEntryActive(e);
    }
    function handleReconfirm(e) {
        const levnt = {
            target: {
                checked: true
            }
        }
        setActive(true);
        setEntryActive(levnt);
        if (profileActive && !_active && !changeConfirmed) {
            setShowDlgChanged(false);
            setChangeConfirmed(true);
        }
    }
    function handleCloseDlg() {
        setShowDlgChanged(false);
    }

    return (
        <>
            <Button
                className="m-1 bav"
                onClick={handleAuswertung}
                variant="contained"
                style={{
                    maxWidth: "90px",
                    maxHeight: "20px",
                    minWidth: "90px",
                    minHeight: "20px",
                    fontSize: "0.75rem"
                }}
            >
                Auswertung
            </Button>
            <Button
                className="m-1 bav"
                onClick={handleShow}
                variant="contained"
                style={{
                    maxWidth: "55px",
                    maxHeight: "20px",
                    minWidth: "50px",
                    minWidth: "5px",
                    minHeight: "20px",
                    fontSize: "0.75rem"
                }}
            >
                Ansicht
            </Button>
            <Button
                className="m-1 bav"
                onClick={handleEdit}
                variant="contained"
                style={{
                    maxWidth: "77px",
                    maxHeight: "20px",
                    minWidth: "77px",
                    minHeight: "20px",
                    fontSize: "0.75rem"
                }}
                disabled={disabled}
            >
                Bearbeiten
            </Button>

            {hasAdmin ? (
                <Button
                    className="m-1 bav"
                    onClick={handleDelete}
                    variant="contained"
                    style={{
                        maxWidth: "70px",
                        maxHeight: "20px",
                        minWidth: "60px",
                        minHeight: "20px",
                        fontSize: "0.75rem"
                    }}
                    disabled={disabled}
                >
                    Löschen
                </Button>) : <div style={{
                    maxWidth: "100px",
                    maxHeight: "20px",
                    minWidth: "65px",
                    minHeight: "20px",
                    fontSize: "0.75rem"
                }}></div>  }
            <Checkbox onChange={handleActive} checked={_active}></Checkbox>
            <Dialog
                open={showDlgChanged}
                onClose={handleCloseDlg}
                aria-labelledby="alert-dialog-title-changed"
                aria-describedby="alert-dialog-description-changed"
            >
                <DialogTitle id="alert-dialog-title-changed">
                    {"Anlageprofil bestätigen"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-changed">
                        Bitte stellen Sie sicher, dass nach der Aktivierung die Profilierung vom Kunden unterschrieben wird und dass in Finnova eine Notiz dazu erfasst wird.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className="m-1 bav" onClick={handleCloseDlg}>Abbrechen</Button>
                    <Button variant="contained" className="m-1 bav" onClick={handleReconfirm} autoFocus>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
            
        </>
    );
}
export default BAVButtonColumn;
