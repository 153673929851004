import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import QuestionMark from "@mui/icons-material/QuestionMark";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState, useEffect } from "react";

function BAVToggleButton(props) {
  const buttonIcons = [
    <QuestionMark />,
    <CheckBoxIcon color="success" />,
    <CancelIcon color="warning" />,
  ];
  const [buttonState, setButtonState] = useState(0);
  const [buttonIcon, setButtonIcon] = useState(buttonIcons[0]);
  const maxState = 2;
  function initButtonValue(val) {
    var varname = "value_" + props.button;
    var varvalue = props[varname] || 0;
    var newState = varvalue;
    var newIcon = buttonIcons[varvalue];
    setButtonIcon(newIcon);
    setButtonState(newState);
  }
  function changeState(event) {
    var newState;
    var newIcon;
    if (event.refresh == 1) {
      var varname = "value_" + props.button;
      var varvalue = props[varname] || 0;
      newState = varvalue;
      newIcon = buttonIcons[varvalue];
    } else {
      if (buttonState == maxState) {
        newState = 0;
        newIcon = buttonIcons[0];
      } else {
        var newState = parseInt(buttonState) + 1;
        newIcon = buttonIcons[newState];
      }
    }
    setButtonIcon(newIcon);
    setButtonState(newState);
    if (typeof props.callback == "function") {
      props.callback(props, newState);
    }
  }
  useEffect(() => {
    initButtonValue();
  }, []);
    if (props.readOnly) {
        return (
            <>
                <ToggleButton
                    value="bold"
                    aria-label="bold"
                    size="small"
                    disabled={true}
                >
                    {buttonIcon}
                </ToggleButton>
            </>
        );
    } else {
        return (
            <>
                <ToggleButton
                    value="bold"
                    aria-label="bold"
                    size="small"
                    onClick={changeState}
                >
                    {buttonIcon}
                </ToggleButton>
            </>
        );
    }
}
export default BAVToggleButton;
