import * as React from "react";
import Alert from "@mui/material/Alert";
import {
    DataGrid,
    GridColDef,
    deDE
} from "@mui/x-data-grid";
import "./BAVEntryTable.css";
import BAVButton from "./BAVButton";
import { Container } from "reactstrap";
import {
    QueryClient,
    QueryClientProvider
} from "react-query";
import { useState, useEffect } from "react";
import { msalInstance } from "../index";
import { convertUTCDateToLocalDate } from '../utils/Util.jsx';
import { Button, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { debugLog } from "../utils/Util";
import { getUserToken } from "../utils/AuthTokenReader.jsx";

const queryClient = new QueryClient();


export default function ProfileReconfirmationDialog({ activeKdEntries, activePfEntries, activeSpEntries }) {
    return (
        <QueryClientProvider client={queryClient}>
            <ProfileReconfirmation activeKdEntries={activeKdEntries} activePfEntries={activePfEntries} activeSpEntries={activeSpEntries} />
        </QueryClientProvider>
    );
}

function ProfileReconfirmation({ activeKdEntries, activePfEntries, activeSpEntries }) {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState({ severity: "info", text: "" });
    const [showSingleDlg, setShowSingleDlg] = useState(false);
    const [showMultiDlg, setShowMultiDlg] = useState(false);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const activeProfiles = [...activeKdEntries, ...activePfEntries, ...activeSpEntries];
    const [selectedRows, setSelectedRows] = useState(activeProfiles.map((profile) => profile.entryId));
    const account = msalInstance.getActiveAccount();
    const columns: GridColDef[] = [

        {
            field: "entryId",
            headerName: "Id",
            headerClassName: "BAVColumnHeader",
            width: 120,
        },
        {
            field: "entryType",
            headerName: "Profiltyp",
            headerClassName: "BAVColumnHeader",
            width: 120,
            valueGetter: ({ value }) => {
                if (value == 1) {
                    return 'Kundenprofil';
                }
                if (value == 2) {
                    return 'Portfolioprofil';
                }
                if (value == 3) {
                    return 'Sparplanprofil';
                }
                if (value == 4) {
                    return 'Vorsorgeprofil'
                }
            }
        },
        {
            field: "createDate",
            headerName: "Erstellungdatum",
            headerClassName: "BAVColumnHeader",
            width: 165,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                }),
        },
        {
            field: "changeDate",
            headerName: "Letzte Änderung",
            headerClassName: "BAVColumnHeader",
            width: 165,
            valueGetter: ({ value }) =>
                value &&
                new Date(value).toLocaleString("de-CH", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                }),
        },
        {
            field: "editKtGnr",
            headerName: "Porfolionummer",
            headerClassName: "BAVColumnHeader",
            width: 180,
        },
    ];
    // Auth Token
    const [options, setOptions] = useState();
    const [token, setToken] = useState();
    const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
    };
    const reInitToken = new Promise((resolve, reject) => {
        if (token && token.expiresOn < Date.now()) {
            debugLog('Start initToken');
            initToken()
                .then((res) => {
                    debugLog('Finished initToken');
                    resolve('initToken');
                })
        } else {
            debugLog('tokenValid');
            resolve('tokenValid');
        }
    });
    useEffect(() => {
        initToken();
    }, []);


    const handleClickOpen = () => {
        if (activeKdEntries.length == 1 && activePfEntries.length == 1 && activeSpEntries == 0) {
            setShowSingleDlg(true);
        } else {
            setShowMultiDlg(true);
        }
        };

    const handleClose = () => {
        setShowMultiDlg(false);
        setShowSingleDlg(false);
        setShowAlert(false);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    function selectCallback(success, msg) {
        if (!success) {
            setAlertData({ severity: "error", text: msg });
            setShowAlert(true);
        } else {
            setAlertData({});
            setShowAlert(false);
            setShowMultiDlg(false);
            setShowSingleDlg(false);
        }
    }
    const handleReconfirmProfiles = () => {
        setShowMultiDlg(false);
        setShowSingleDlg(true);
       
      
    };
    function handleReconfirm() {
        setShowSingleDlg(false);
        if (!selectedRowsData.length) {
            updateReconfirmData(activeProfiles);
        } else {
            updateReconfirmData(selectedRowsData);
        }
    }

    function updateReconfirmData(selection) {
        for (let i = 0; i < selection.length; i++) {
            selection[i].changedBy = account.name;
            selection[i].changeDate = convertUTCDateToLocalDate(new Date());
            selection[i].validFrom = convertUTCDateToLocalDate(new Date());
            selection[i].validTo = new Date("2099-12-31");
            let requestOptions = {
                method: "PUT",
                headers: options.headers,
                body: JSON.stringify(selection[i]),
            };
            fetch("/entries/" + selection[i].entryId, requestOptions).then(function () {
                debugLog("");
            });
        }
        window.location.reload();
    }
    return (
        <>
            <Tooltip title="Profile für 3 Jahre bestätigen">
                <Button startIcon={<FactCheckIcon />}
                    className="m-3 btnConfirmProfile"
                    variant="contained"
                    disabled={activeProfiles.length == 0 || activeKdEntries.length == 0}
                    onClick={handleClickOpen}
                    style={{
                        maxWidth: "220px",
                        maxHeight: "25px",
                        minWidth: "30px",
                        minHeight: "25px",
                    }}

                >
                    Profile bestätigen
                </Button >
            </Tooltip>
            <Dialog
                open={showSingleDlg}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Anlageprofile bestätigen"}
                </DialogTitle>
                <DialogContent>


                    <DialogContentText id="alert-dialog-description">
                        Hiermit bestätigen Sie, dass Ihre aktiven Anlageprofile weiterhin unverändert gültig sind. <br />
                        Die Gültigkeit der aktiven Anlageprofile wird durch Ihre Bestätigung um 3 Jahre verlängert. Bitte stellen Sie sicher, dass in Finnova ein entsprechender Notizeintrag erfasst wird.
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className="m-1 bav" onClick={handleClose}>Abbrechen</Button>
                    <Button variant="contained" className="m-1 bav" onClick={handleReconfirm} autoFocus>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showMultiDlg}
                onClose={handleClose}
                fullWidth={false}
                maxWidth={"md"}
            >
                <DialogTitle>Bestätigung der aktiven Profile</DialogTitle>
                <DialogContent style={{ height: "300px", minWidth: "500px" }}>
                    <Container style={{ maxHeight: "80%", overflow: "auto" }}>
                        <Container fluid className="pt-2">
                            <DataGrid checkboxSelection disableRowSelectionOnClick
                                className="BAVEntryTable"
                                getRowId={(row) => row.entryId}
                                disableColumnMenu={true}
                                loading={loading}
                                localeText={
                                    deDE.components.MuiDataGrid.defaultProps.localeText
                                }
                                columns={columns}
                                rows={activeProfiles}
                                rowSelectionModel={selectedRows}
                                rowHeight={40}
                                density={"compact"}
                                hideFooterPagination={true}
                                hideFooter={true}
                                onRowSelectionModelChange={(ids) => {
                                    setSelectedRowsData(ids.map((id) => activeProfiles.find((row) => row.entryId === id)));
                                    setSelectedRows(ids);
                                }}
                            />
                        </Container>
                    </Container>
                </DialogContent>
                {showAlert && (
                    <Alert severity={alertData.severity} onClose={handleCloseAlert}>
                        {alertData.text}
                    </Alert>
                )}
                <DialogActions>
                    <BAVButton variant="contained" onClick={handleClose}>
                        Abbrechen
                    </BAVButton>
                    <BAVButton variant="contained" onClick={handleReconfirmProfiles} disabled={selectedRowsData.length == 0}>
                        Bestätigen
                    </BAVButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
