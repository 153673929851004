import React, { Component, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { msalInstance } from "../index";
import { getUserToken } from "../utils/AuthTokenReader.jsx";
import { useState } from "react";
import { DialogTitle, Container, Paper, Grid  } from "@mui/material";
import {ReactComponent as BavLine} from "../images/title_line.svg";
import { styled } from '@mui/material/styles';
import Profilierungsuebersicht from "../images/Profilierungsuebersicht.png";
import Kundenuebersicht from "../images/Kundenuebersicht.png";
import Profilierungsformular from "../images/Profilierungformular.png";
import "./Home.css";
import { debugLog } from "../utils/Util";

function Home() {
  const account = msalInstance.getActiveAccount();
  debugLog(account);
  // Auth Token
  const [options, setOptions] = useState();
  const [token, setToken] = useState();
  const initToken = async () => {
        const ltoken = await getUserToken();
        setToken(ltoken);
        const headers = new Headers();
        const bearer = `Bearer ${ltoken.accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        setOptions({ headers: headers });
  };
  useEffect(() => {
    initToken();
  }, []);
 const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function fullSize(e){
    e.target.className = "BavImgFull";
}
function smallSize(e){
    e.target.className = "BavImg";
}
  return (
      <>
      <Container className="mainContainer">
        <Paper>
          <div className="pageContainer">
            <Container className="pt-3" fluid="true">
              <DialogTitle>
                <Typography  align="center"><span style={{fontSize:18 }}><b>Anlageberatungs-Profilierung</b></span></Typography>
                <Typography  align="center"><i>Testversion</i></Typography>
              </DialogTitle>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}><BavLine/></div>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Item>
                    <b>Profilierungs&uuml;bersicht</b><p/>
                    Die Profilierungs&uuml;bersicht kann &uuml;ber den Men&uuml;punkt <u>Profilierung</u> ge&ouml;ffnet werden.
                    Hier werden alle Kunden aufgelistet, die bereits in der ABP Applikation angelegt und profiliert wurden.<br/>
                    Im Suchfeld (1) kann entweder nach Kundenname, Kundennummer oder Kundenlaufnummer gesucht werden.Die Kundennummer kann auch ohne "." eingegeben werden.<br/>Mit der Berater-Auswahlbox (2) 
                    kann nach Kunden f&uuml;r einen bestimmten Berater gefiltert werden. Die Suche wird durch Klick auf die Schaltfl&auml;che <b>"Suchen"</b>(3) ausgef&uuml;hrt. Ist kein Filter gesetzt, werden alle Kunden angezeigt.<p/>
                    Mittels Doppelklick auf einen Kunden (4), kann die Detail&uuml;bersicht des Kunden ge&ouml;ffnet werden. <br/>
                    Wenn ein Kunde noch nicht profiliert wurde, kann dieser &uuml;ber die Schaltfl&auml;che "Neuer Kunde" gesucht und aus dem Finnova Datenstamm &uuml;bernommen werden.
                    Nach der Auswahl des Kunden wird automatisch das Profilierungsformular Kundenebene ge&ouml;ffnet.
                    <p/>
                    <img className="BavImg" src={Profilierungsuebersicht} onMouseOver={(e) => {fullSize(e)}}  onMouseOut={(e) => {smallSize(e)}}/>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                <Item><b>Kunden&uuml;bersicht</b><p/>
                 Die Kunden&uuml;bersicht ist in die drei Sektionen Kunde, Portfolio und Sparplan unterteilt. In jeder Sektion werden die erstellen Profilierungen f&uuml;r den
                 Kunden angezeigt.<br/> Durch Klick auf die Schaltfl&auml;che <b>"Neu"</b>(1) kann eine neue Profilierung erstellt werden. 
                 F&uuml;r jede erstellte Profilierung kann &uuml;ber die Schaltfl&auml;che <b>"Auswertung"</b>(2) eine Auswertung erstellt werden. Mit Klick auf die Schaltfl&auml;che <b>"Bearbeiten"</b>
                 kann die Profilierung bearbeitet werden. Eine Bearbeitung ist nur m&ouml;glich, wenn die Profilierung nicht aktiviert wurde (mittels CheckBox). Die Schaltfl&auml;che <b>"Kopieren"</b> (4)
                 kopiert eine vorhandene Profilierung.<br/> Hat ein Benutzer Admin Berechtigungen kann er eine Profilierung &Uuml;ber Schaltfl&auml;che <b>"L&ouml;schen"</b>(5) l&ouml;schen.<br/>
                 Mit Schaltfl&auml;che <b>"Zur&uuml;ck"</b> kann zur&uuml;ck in die Profilierungs&uuml;bersicht gesprungen werden.
                 <p/>
                 <img className="BavImg" src={Kundenuebersicht} onMouseOver={(e) => {fullSize(e)}}  onMouseOut={(e) => {smallSize(e)}}/>
                </Item>
                </Grid>
                <Grid item xs={4}>
                <Item><b>Profilierungsformulare</b><p/>
                Die verschiedenen Profilierungsformulare sind gleich aufgebaut und unterscheiden sich nur in den verschiedenen Fragestellungen.<br/> In der Kopfleiste (1)
                des Formulars wird eine Leiste mit der Anzahl der Fragen eingeblendet. Die aktuell angezeigte Frage ist gr&uuml;n hinterlegt.<br/> &Uuml;ber die Schaltfl&auml;che 
                <b>"N&auml;chster Schritt"</b>(2) kann zur n&auml;chsten Frage gewechselt werden. Mit Bet&auml;tigung der Schaltfl&auml;che <b>"Vorheriger Schritt"</b> (3) kann auf die 
                letzte Frage zur&uuml;ckgesprungen werden. &Uuml;ber die Schaltfl&auml;che <b>"Zur&uuml;ck zum Kunden"</b> (4) kann auf die Kunden&uuml;bersicht gesprungen werden.
                <p/>
                <img className="BavImg" src={Profilierungsformular} onMouseOver={(e) => {fullSize(e)}}  onMouseOut={(e) => {smallSize(e)}}/>
                </Item>
                </Grid>
            </Grid>
              <Grid container spacing={2}>
             <Grid item xs={12}>
                <Item><p/><p/><i>Die Screenshots werden automatisch vergr&ouml;ssert, wenn sie mit der Maus selektiert werden.</i>
                </Item>
             </Grid>
            </Grid>
            </Container>
          </div>
        </Paper>
      </Container>
    </>
);



}
export default Home;
